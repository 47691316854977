<template>
<div class="bg-degradado-green pt-3 pb-16 container-tarjeta-app ">
    <Navbar path="/"/>
    <p class="title-2 text-center">App</p>

    <section class="flex-container offset">
        <article class="flex-item justify-align-center">
            <img class="img-small" src="../assets/animacion/15/Yipi_AnimacionWeb_15.gif"/>
        </article>
        <article >
            <ul class="flex-item"> 
                <li class="flex-container"> 
                    <p class="circle-blue justify-align-center text-circle">01</p>
                    <p class="description-circle">Descarga la app.</p>
                </li>
                <li class="flex-container"> 
                  <p class="circle-blue justify-align-center text-circle">02</p>
                   <p class="description-circle">Crea tu cuenta.</p>
                </li>
                <li class="flex-container"> 
                   <p class="circle-blue justify-align-center text-circle">03</p>
                   <p class="description-circle">Recarga saldo.</p>
                </li>
                <li class="flex-container"> 
                    <p class="circle-blue justify-align-center text-circle">04</p>
                   <p class="description-circle">Solicita tu viaje.</p>
                </li>
        
            </ul>
        </article>
    </section>
    <div class="justify-align-center flex-wrap">
     <img class="btn-app" src="../assets/320x480/Boton_AppStore.svg"/>
        <a href="https://play.google.com/store/apps/details?id=com.yipi.usuarios&hl=en"
                  target="_blank"
          rel="noopener noreferrer"> 
          <img class="btn-app" src="../assets/320x480/Boton_GooglePlay.svg"/>
        </a>
    </div>

</div>
</template>
<script>
import Navbar from "../components/commons/Navbar.vue";
export default {
    components:{
        Navbar
    }
}
</script>
<style >
    @import '../styles/tarjeta.css';
        @import '../styles/quienesSomos.css';
</style>